import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import SEO from '../components/SEO';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title={t('404MetaTitle')} />
      <h1>{t('404Title')}</h1>
      <p>{t('404Text')}</p>
    </>
  );
};

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotFoundPage
